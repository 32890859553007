import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'reactstrap'
import Slider from 'react-slick'
import Layout from '../components/layout'
import Button from '../components/button'

export default ({ data }) => {
  // state
  const [currentImage, setCurrentImage] = useState(1)
  console.log('currentImage: ', currentImage)

  const project = data.markdownRemark
  let images = []
  data.projectsImages.edges.find(edge => {
    const path = `projects/${project.frontmatter.graphqlKey}/${
      edge.node.name
    }.jpg`
    if (edge.node.relativePath === path) {
      images.push(edge)
    }
  })
  const sliderSettings = {
    // Implement customPaging setting on projectPage template
    customPaging: function(i) {
      return (
        <a>
          <Img fluid={images[i].node.childImageSharp.fluid} />
        </a>
      )
    },
    arrows: false,
    fade: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const slider2Settings = {
    arrows: images.length > 5,
    infinite: false,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    // show less slides on sm and med screens
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: images.length > 3,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  }
  console.log('infinite: ', slider2Settings.infinite)
  console.log(
    'project.frontmatter.graphqlKey: ',
    project.frontmatter.graphqlKey
  )
  return (
    <Layout>
      <div className="project-page">
        <Container fluid>
          <Row>
            <Col className="project-right-col" lg={4}>
              <h1>{project.frontmatter.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: project.html }} />
              {project.frontmatter.url !== '' && (
                <a className="d-block p-0" href={project.frontmatter.url}>
                  {project.frontmatter.urlShort}
                </a>
              )}
              {/* Only implemented for Terra Bella brochure */}
              {project.frontmatter.url2 !== '' && (
                <a
                  className="d-block pt-0"
                  href={require('../assets/downloadables/1075-terra-bella-brochure.pdf')}
                >
                  {project.frontmatter.url2}
                </a>
              )}
              <Link to="/portfolio">
                <Button>View Our Portfolio</Button>
              </Link>
            </Col>
            <Col className="project-left-col" lg={8}>
              <Slider {...sliderSettings}>
                {/* {images.map((image, index) => ( */}
                <Img
                  alt=""
                  fluid={images[currentImage - 1].node.childImageSharp.fluid}
                />
                {/* ))} */}
              </Slider>
              <div className="slider2">
                <Slider {...slider2Settings}>
                  {images.map((image, index) => (
                    <div
                      className="slider2-image-wrapper"
                      onClick={() => setCurrentImage(index + 1)}
                    >
                      <Img alt="" fluid={image.node.childImageSharp.fluid} />
                    </div>
                  ))}
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        graphqlKey
        url
        urlShort
        url2
      }
    }

    projectsImages: allFile(
      sort: { order: ASC, fields: [absolutePath] }
      filter: { relativePath: { regex: "/projects/.*.jpg/" } }
    ) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
